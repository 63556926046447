<template>
 <div style=" margin: -20px -15px;">
   <a-spin :spinning="spinning">
  <div class="ZKAuth">
    <div class="ZKAuth-left">
      <!--        身份选择-->
      <div>
        <a-menu
            style="width: 100%"
            v-model="selectId"
            mode="inline"
        >
          <a-menu-item
              v-for="(item,index) in templateList"
              @click="selectStatus(item.id)"
              :key="item.id">
            {{item.title}}
          </a-menu-item>
        </a-menu>
      </div>
    </div>
    <div class="ZKAuth-right">
      <div class="ZKAuth-right-top">
        <!--   顶部搜索  -->
        <div style="display: flex;text-align: center;margin-left: 10px">
          <div class="ZKAuth-right-top-input">
            城市: <a-input
              v-model="searchForm.city"
              style="width: 60%"
              @pressEnter="enterBtn"
              placeholder="请输入城市" />
          </div>
          <div class="ZKAuth-right-top-input">
            医院: <a-input
              v-model="searchForm.hospital"
              style="width:60%"
              @pressEnter="enterBtn"
              placeholder="请输入医院" />
          </div>
          <div class="ZKAuth-right-top-input">
            姓名: <a-input
              v-model="searchForm.name"
              style="width: 60%"
              @pressEnter="enterBtn"
              placeholder="请输入姓名" />
          </div>
          <div class="ZKAuth-right-top-input">
            手机号: <a-input
              v-model="searchForm.tel"
              style="width:60%"
              @pressEnter="enterBtn"
              placeholder="请输入手机号" />
          </div>
        </div>
        <div>
          <a-button
              @click="selectBtn"
              style="margin-left: 20px">查询</a-button>
          <a-button
              @click="restBtn"
              style="margin-left: 10px">重置</a-button>
        </div>
      </div>
      <div class="ZKAuth-right-table">
        <!--   列表   -->
        <div class="ZKAuth-right-table-top-btn">
          <!--  列表上方按钮      -->
          <a-button @click="addBtn" type="primary">新增</a-button>
          <a-popconfirm style="margin-left: 10px" placement="top" ok-text="是" cancel-text="否" @confirm="resetConfirm">
            <template slot="title">
              <p>是否重置密码？</p>
            </template>
            <a-button>重置密码</a-button>
          </a-popconfirm>
          <a-popconfirm style="margin-left: 10px" placement="top" ok-text="是" cancel-text="否" @confirm="delConfirm">
            <template slot="title">
              <p>是否删除？</p>
            </template>
            <a-button type="danger">删除</a-button>
          </a-popconfirm>
        </div>
        <!--   列表   -->
        <div  class="ZKAuth-right-table-top-table">
          <a-table
              :pagination="false"
              style="width: 99%"
              :columns="columns"
              :rowKey='record=>record.id'
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              :data-source="tableList">
          <span slot="operation" slot-scope="text,record">
            <a @click="lookBtn(record)">查看</a>
            <a
                @click="amendBtn(record)"
                style="margin-left: 10px">修改</a>
          </span>
            <span slot="createTime" slot-scope="text,record">
            <div v-if="text !== null && text !== undefined && text !== ''">{{(text).slice(0,-9)}}</div>
            <div v-else> </div>
          </span>
          </a-table>
        </div>
        <!--      分页-->
        <div style="width: 100%">
          <div class="pageClass">
            <a-pagination
                @change="pageChange"
                :total="total" show-less-items />
          </div>
        </div>
      </div>
    </div>
<!----------------------------------     对话框     --------------------------------->
    <!--    新增对话框-->
    <a-drawer
        title="新增"
        placement="right"
        :closable="false"
        :visible="addVisible"
        @close="addClose"
        width="500"
    >
      <div class="addForms">
        <a-form-model
            ref="addRuleForm"
            :model="addForm"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol">
          <a-form-model-item label="账号类型" prop="grade">
            <a-select
                show-search
                option-filter-prop="children"
                @change="selectnumberType"
                placeholder="请选择类型"
                v-model="addForm.grade"
                style="width: 340px">
              <a-select-option value="1">
                医院级账号
              </a-select-option>
              <a-select-option value="2">
                市级帐号
              </a-select-option>
              <a-select-option value="3">
                省级账号
              </a-select-option>
              <a-select-option value="4">
                顶级账号
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="省份" prop="province">
            <a-select
                show-search
                option-filter-prop="children"
                v-model="addForm.province"
                placeholder="请选择省份"
                style="width: 340px">
              <a-select-option
                  v-for="(item,index) in options"
                  :key="item.code"
                  :value="item.name"
                  @click="selectProvince(item.name,index)">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="城市" prop="city">
            <a-select
                show-search
                option-filter-prop="children"
                v-model="addForm.city"
                placeholder="请选择城市"
                style="width: 340px">
              <a-select-option
                  v-for="item in cityList"
                  :key="item.code"
                  :value="item.name"
                  @click="selectCity(item.name)">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="医院" prop="hospitalId">
            <a-select
                show-search
                option-filter-prop="children"
                v-model="addForm.hospitalId"
                placeholder="请选择医院"
                style="width: 340px">
              <a-select-option
                  v-for="(item,index) in hospitalList"
                  :key="item.id"
                  :value="item.id"
                  @click="selectHospital(item.id,index)">
                {{item.hospitalName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="联系人姓名" prop="name">
            <a-input v-model="addForm.name" placeholder="联系人姓名" />
          </a-form-model-item>
          <a-form-model-item label="登陆账号" prop="tel">
            <a-input v-model="addForm.tel" placeholder="请输入登陆账号" />
          </a-form-model-item>
          <a-form-model-item label="登陆密码" prop="password">
            <a-input v-model="addForm.password" placeholder="请输入登陆密码" />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset:14}">
            <a-button style="margin-right: 10px;" @click="addCloseBtn">
              取消
            </a-button>
            <a-button type="primary" @click="onSubmit">
              确定
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-drawer>
    <!--    修改对话框-->
    <a-drawer
        title="修改"
        placement="right"
        :closable="false"
        :visible="restVisible"
        @close="restClose"
        width="500"
    >
      <div class="addForms">
        <a-form-model
            :model="restForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol">
          <a-form-model-item label="账号类型">
            <a-select
                show-search
                option-filter-prop="children"
                @change="restselectType"
                placeholder="请选择类型"
                v-model="restForm.grade"
                style="width: 340px">
              <a-select-option value="1">
                医院级账号
              </a-select-option>
              <a-select-option value="2">
                市级帐号
              </a-select-option>
              <a-select-option value="3">
                省级账号
              </a-select-option>
              <a-select-option value="4">
                顶级账号
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="省份">
            <a-select
                show-search
                option-filter-prop="children"
                v-model="restForm.province"
                placeholder="请选择省份"
                style="width: 340px">
              <a-select-option
                  v-for="(item,index) in options"
                  :key="item.code"
                  :value="item.name"
                  @click="restselectProvince(item.name,index)">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="城市" >
            <a-select
                show-search
                option-filter-prop="children"
                v-model="restForm.city"
                placeholder="请选择城市"
                style="width: 340px">
              <a-select-option
                  v-for="item in restcityList"
                  :key="item.code"
                  :value="item.name"
                  @click="restselectCity(item.name)">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="医院" >
            <a-select
                show-search
                option-filter-prop="children"
                v-model="restForm.hospitalId"
                placeholder="请选择医院"
                style="width: 340px">
              <a-select-option
                  v-for="(item,index) in resthospitalList"
                  :key="item.id"
                  :value="item.id"
                  @click="restselectHospital(item.id,index)">
                {{item.hospitalName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="联系人姓名">
            <a-input v-model="restForm.name" placeholder="联系人姓名" />
          </a-form-model-item>
          <a-form-model-item label="登陆账号" >
            <a-input v-model="restForm.tel" placeholder="请输入登陆账号" />
          </a-form-model-item>
          <a-form-model-item label="登陆密码">
            <a-input v-model="restForm.password" placeholder="请输入登陆密码" />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset:14}">
            <a-button style="margin-right: 10px;" @click="restCloseBtn">
              取消
            </a-button>
            <a-button type="primary" @click="restSubmit">
              确定
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-drawer>
    <!--   查看对话框-->
    <a-drawer
        title="查看"
        placement="right"
        :closable="false"
        :visible="lookVisible"
        @close="lookClose"
        width="350"
    >
      <div class="addForms">
        <a-form-model
            :model="lookForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol">
          <a-form-model-item label="身份类型">
            <div>{{lookForm.grade}}</div>
          </a-form-model-item>
          <a-form-model-item label="省份" >
            <div>{{lookForm.province}}</div>
          </a-form-model-item>
          <a-form-model-item label="城市" >
            <div>{{lookForm.city}}</div>
          </a-form-model-item>
          <a-form-model-item label="医院">
            <div>{{lookForm.hospitalName}}</div>
          </a-form-model-item>
          <a-form-model-item label="姓名" >
            <div>{{lookForm.name}}</div>
          </a-form-model-item>
          <a-form-model-item label="账号" >
            <div>{{lookForm.tel}}</div>
          </a-form-model-item>
          <a-form-model-item label="创建时间" >
            <div>{{lookForm.createTime}}</div>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset:18}">
            <a-button  @click="lookCloseBtn">
              关闭
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-drawer>
  </div>
   </a-spin>
</div>
</template>

<script>
import {delAdmin, getHospital, getZkList, postAdmin, putAdmin, putPassword} from "../../../service/case_api";
import City from '@/assets/city.json'
export default {
  name: "ZKAuth",
  data(){
    return{
      listId:'',
      selectId:[1],
      //选择框
      templateList:[
        {
          title: '医院级账号',
          id:1,
        },
        {
          title: '市级帐号',
          id:2,
        },
        {
          title: '省级账号',
          id:3,
        },
        {
          title: '顶级账号',
          id:4,
        },
      ],
      //顶部搜索
      searchForm:{
        city:'',
        hospital:'',
        number:'',
        name:'',
        tel:'',
      },
      lookForm:{
        name: '',
        tel: '',
        hospitalId: '',
        hospitalName: '',
        grade: undefined,
        province: undefined,
        city: '',
        createTime: ''
    },
      columns : [
        {
          title: '账号',
          dataIndex: 'tel',
          width: '15%',
          key: 'tel1',
          align:'center',
        },
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          align:'center',
          width: '8%',
        },
        {
          title: '手机号码',
          dataIndex: 'tel',
          width: '15%',
          align:'center',
          key: 'tel2',
        },
        {
          title: '省份',
          dataIndex: 'province',
          key: 'province',
          ellipsis: true,
          width: '10%',
          align:'center',
        },
        {
          title: '城市',
          dataIndex: 'city',
          key: 'city',
          ellipsis: true,
          width: '10%',
          align:'center',

        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
          key: 'hospitalName',
          ellipsis: true,
          width: '20%',
          align:'center',
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: '12%',
          align:'center',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'address 4',
          width: '12%',
          align:'center',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableList:[],
      //总数
      total:2,
      spinning:false,
      selectedRowKeys: [],
      user_id:'',
      // addVisiblea:false,
      addVisible:false,
      lookVisible:false,
      addForm: {
        tel:'',
        name:'',
        password:'',
        grade:undefined,
        province:undefined,
        city:undefined,
        hospitalId:undefined
      },
      rules:{
        name: [{ required: true, message: '请输入名字', trigger: 'blur' }],
        tel: [{ required: true, message: '请输入登陆账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入登陆密码', trigger: 'blur' }],
        grade:[{ required: true, message: '请选择', trigger: 'change' }],
        province:[{ required: true, message: '请选择', trigger: 'change' }],
        city:[{ required: true, message: '请选择', trigger: 'change' }],
        hospitalId:[{ required: true, message: '请选择', trigger: 'change' }],
      },
      restForm: {
        tel:'',
        name:'',
        password:'',
        grade:undefined,
        province:undefined,
        city:undefined,
        hospitalId:undefined
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      options: City ,
      cityList:[],
      hospitalList:[],
      page_size:'',
      page_no:'',
      restVisible:false,
      restcityList:[],
      resthospitalList:[]
    }
  },
  created() {
    this.getList()
    this.$store.dispatch('setManageHeaderTitle',"质控平台账号")
  },
  methods:{
    //获取列表
    async getList(grade,page_size,page_no,name,city,tel,hospitalName) {
      //设置初始列表，默认显示顶级账号
      if(!grade){
        grade = this.selectId[0]
      }
      const response = await getZkList(grade,page_size,page_no,name,city,tel,hospitalName)
      if (response.code === 0){
        this.spinning = true
        this.tableList = response.data.rows
        this.total = response.data.count
        this.spinning = false
      }else {
        this.$message.warning(response.message)
      }
    },
    //选择身份
    selectStatus(id){
      this.selectId[0] = id
      this.getList(id)
    },
    //选择账号类别
    selectnumberType(value){
      this.addForm.grade = value
    },
    restselectType(value){
      this.restForm.grade = value
    },
    //选择省
    selectProvince(provinceName,index){
      this.cityList = this.options[index].cityList
    },
    //选择城市
    async selectCity(cityName) {
      this.addForm.city = cityName
      const response = await getHospital(this.addForm.province,cityName)
      this.hospitalList = response.data
    },
    //选择医院
    selectHospital(){

    },
    //选择省
    restselectProvince(provinceName,index){
      this.restcityList = this.options[index].cityList
      this.restForm.city = undefined
      this.restForm.hospitalId = undefined
      this.restForm.province = undefined
      this.getHosList(this.restForm.province,this.restForm.city)
    },
    //选择城市
    async restselectCity(cityName) {
      console.log(cityName)
      this.restForm.city = cityName
      await this.getHosList(this.restForm.province, cityName)
    },
    //获取医院列表
    async getHosList(province, city) {
      const response = await getHospital(province, city)
      this.resthospitalList = response.data
    },
    //选择医院
    restselectHospital(){

    },
    //查询  name,city,tel,hospitalName
    selectBtn(){
      const response = this.searchForm
      this.getList(this.selectId[0],this.page_size,this.page_no,response.name,response.city,response.tel,response.hospital)
    },
    enterBtn(){
      this.selectBtn()
    },
    //重置
    restBtn(){
      Object.keys(this.searchForm).forEach(key => this.searchForm[key]= '');
      this.selectBtn()
    },
    //重置密码
    async resetConfirm() {
      let data = this.user_id
     if(data){
       const response = await putPassword(data)
       if(response.code === 0){
         // 刷新界面
         const response = this.searchForm
         await this.getList(this.selectId[0],this.page_size,this.page_no,response.name,response.city,response.tel,response.hospital)
         // 清空选项
         this.user_id = ''
         this.selectedRowKeys = []
         this.$message.success('成功重置密码！')
       }else {
         this.$message.warning('出错'+response.message)
       }
     }else {
       this.$message.warning('请先选择需要重置密码的列表！')
     }
    },
    // 删除
    async delConfirm(){
      var memberIds = this.user_id
      if(memberIds){
        const response = await delAdmin(memberIds)
        if(response.code === 0){
          //删除成功后， 刷新界面
          const response = this.searchForm
          await this.getList(this.selectId[0],this.page_size,this.page_no,response.name,response.city,response.tel,response.hospital)
          // 清空选项
          this.user_id = ''
          this.selectedRowKeys = []
          this.$message.success(response.message)
        }else {
          this.$message.warning('出错'+response.message)
        }
      }else {
        this.$message.success('请先选择需要删除的列表！')
      }

    },
    // 查看
    lookBtn(row){
      this.lookVisible = true
      this.lookForm = row
      let form = this.lookForm
      switch (row.grade){
        case 1:
          form.grade = '医院级账号'
          break;
        case 2:
          form.grade = '市级帐号'
          break;
        case 3:
          form.grade = '省级账号'
          break;
        case 4:
          form.grade = '顶级账号'
          break;
        default:
          break;
      }
    },
    // 修改
    async amendBtn(row) {
      this.listId = row.id
      this.restVisible = true
      // 赋值
      const form = this.restForm
      switch (row.grade){
        case 1:
          form.grade = '医院级账号'
              break;
        case 2:
          form.grade = '市级帐号'
          break;
        case 3:
          form.grade = '省级账号'
          break;
        case 4:
          form.grade = '顶级账号'
          break;
          default:
            break;
      }
      form.province = row.province
      //可以选择城市
      for(let i = 0;i <= this.options.length; i++){
        if(this.options[i]){
          if(this.options[i].name === row.province){
            this.restcityList = this.options[i].cityList
          }
        }
      }
      form.city = row.city
      form.hospitalId = row.hospitalId
      await this.getHosList(row.province,row.city)
      form.tel = row.tel
      form.name = row.name

      // const response = await putAdmin(row.is,this.restForm)
    },
    //分页
    pageChange(page_no,page_size){
      //no 第几页   size  一页显示多少条
      this.page_no = page_no
      this.page_size = page_size
      this.getList(this.selectId[0],page_size,page_no)
    },
    //获取id
    onSelectChange(selectedRowKeys){
      //选择的列表的id
      this.user_id = selectedRowKeys
      //显示是否被选中
      this.selectedRowKeys = selectedRowKeys;
    },
    // 新增按钮
    addBtn(){
      this.addVisible = true
    },
    //新增对话框关闭
    addClose(){
      this.addVisible = false
    },
    restClose(){
      this.restVisible = false
    },
    lookClose(){
      this.lookVisible = false
    },
    //新增对话框的取消按钮
    addCloseBtn(){
      this.addVisible = false
    },
    //新增对话框的取消按钮
    restCloseBtn(){
      this.restVisible = false
    },
    lookCloseBtn(){
      this.lookVisible = false
    },
    //新增对话框提交按钮
    onSubmit() {
      this.$refs.addRuleForm.validate(async valid => {
        if (valid) {
          const data = this.addForm
          const addAdminLists = await postAdmin(data)
          if (addAdminLists.code === 0) {
            //关闭对话框
            this.addVisible = false
            //清空表单
            this.addForm = {}
            this.$message.success("添加成功！")
            //刷新列表
            await this.getList(this.selectId[0],this.page_size,this.page_no)
          } else {
            this.$message.warning("出错！"+addAdminLists.message)
          }
        } else {
          return false;
        }
      });
    },
    //修改对话框提交按钮
    async restSubmit() {
      const data = this.restForm
      switch (data.grade) {
        case '医院级账号':
          data.grade = 1
          break;
        case '市级帐号':
          data.grade = 2
          break;
        case '省级账号':
          data.grade = 3
          break;
        case '顶级账号':
          data.grade = 4
          break;
        default:
          break;
      }
      const addAdminLists = await putAdmin(this.listId, data)
      if (addAdminLists.code === 0) {
        //关闭对话框
        this.restVisible = false
        //清空表单
        this.restForm = {}
        this.$message.success("修改成功！")
        //刷新列表
        const response = this.searchForm
        await this.getList(this.selectId[0], this.page_size, this.page_no, response.name, response.city, response.tel, response.hospital)
      } else {
        this.$message.warning("出错！" + addAdminLists.message)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.ZKAuth{
  width: 100%;
  display: flex;
  .ZKAuth-left{
    width: 10%;
  }
  .ZKAuth-right{
    width: 90%;
    border-left: 15px solid #f5f5f5;
    min-height: 800px;
    .ZKAuth-right-top{
      padding-top: 15px;
      width: 100%;
      height: 120px;
      border-bottom: 10px solid #f5f5f5;
      .ZKAuth-right-top-input{
        width: 25%;
        height: 50px;
        line-height: 50px;
      }
    }
    .ZKAuth-right-table{
      width: 100%;
      .ZKAuth-right-table-top-btn{
        width: 100%;
        height: 50px ;
        margin-left: 20px;
        margin-top: 15px;
      }
      .ZKAuth-right-table-top-table{
        width: 100%;
       margin-left: 15px;
      }
    }
    .pageClass{
      width: 100%;
      text-align: right;
      float: right;
      margin-top: 15px;
    }
  }
}
</style>